import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import aboutImg from '../../assets/images/about-me_.jpeg';
import { motion } from 'framer-motion';

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            // setLetterClass('text-animate-hover');
        }, 4000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <>
            <div className="container2 about-page">
                <br />
                <br />
                
                <h1>
                    <AnimatedLetters 
                        letterClass={letterClass}
                        strArray={['S', 'o', 'b', 'r', 'e', ' ', 'm', 'í']}
                        idx={15}
                    />
                </h1>

                <br/>
                <br/>

                <div className="whole-about">

                    <div className="left">
                        <div className="card-about">
                            <div className="front-card-about">
                                <div className="front-details">

                                 <p>
                                    ¡Hola a todos! Soy <span>Martín Arcos </span> y estoy emocionado de darte la bienvenida.
                                    <br />
                                    <br />
                                    Soy Analista en Sistemas y Programador con experiencia en el Ministerio de Economía de la Provincia del Chubut, donde he ayudado a realizar diversas tareas de forma más eficiente. Me dedico a explorar nuevas tecnologías y estoy en constante aprendizaje sobre programación, desarrollo web y contribuciones al código abierto.
                                    <br />
                                    <br />
                                    Además de mi trabajo en tecnología, disfruto mucho de la naturaleza, lo que me ayuda a mantener un equilibrio entre mi vida profesional y personal.
                                    <br />
                                    <br />
                                    <q>"Si puedes imaginarlo, puedes programarlo" Alejandro Taboada</q></p>
                                </div>
                            </div>
                            
                            <div className="back-card-about">
                                <div className="details">
                                    <div className="sub-details">
                                        <h5>Analista Programador</h5>
                                        <ul>
                                            <li>Universidad Nacional San Juan Bosco</li>
                                            <li>Trelew | Chubut | Argentina</li>
                                        </ul>
                                    </div>
                                    <div className="sub-details">
                                        <h5>Argentina Programa</h5>
                                        <ul>
                                            <li>2022</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                   
                </div>
                <div className="right">
                        <motion.div drag className="box" whileTap={{ cursor: "grabbing" }}>
                            <div className="content">
                                <img src={aboutImg} alt="" />
                                <h3>Arrastrame!</h3>
                            </div>
                        </motion.div>                    
                    </div> 
            </div>
        </>
    );
}
export default About;
