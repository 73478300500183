// projectData.js

import PageContact from '../../assets/images/pageContact.gif';
import Embalsado from '../../assets/images/Embalsado-de-Sierpinski.gif';
import TicTacToe from '../../assets/images/tic-tac-toe.gif';
import FollowX from '../../assets/images/FollowX.gif';
import Sec2 from '../../assets/images/SEC2.gif';
import AnalisisCredito from '../../assets/images/Analisis-de-credito.gif';
import ControlTransacciones from '../../assets/images/controlTransacciones.gif';
import GestionMutuales from '../../assets/images/gestionMutuales.gif';

const projectData = [
  {
    image: PageContact,
    title: "Contacto personal",
    description: "Página que muestra mis datos de contacto de manera resumida y dinámica utilizando React Js, Tailwind CSS",
    githubLink: "https://github.com/cozakoo/React_contact_martinArcos",
    liveLink: "https://martin-arcos-contact.netlify.app/"
  },
  {
    image: Sec2,
    title: "Sistema Web del SEC",
    description: 'Sistema completo para gestionar afiliados, cursos y eventos con control de datos, pagos y asistencias para una gestión eficiente y segura.',
    githubLink: "https://github.com/cozakoo/Python_Django_SistemaSEC",
    liveLink: "https://github.com/cozakoo/Python_Django_SistemaSEC"
  },
  {
    image: AnalisisCredito,
    title: "Análisis de Créditos",
    description: 'Compara archivos generados por un programa en COBOL, uno en formato .xls y otro en formato .csv, para analizar la información relacionada con créditos.',
    githubLink: "https://github.com/cozakoo/Java_AnalisisDeCreditos",
    liveLink: "https://drive.google.com/drive/folders/1UBqlx9E98eQzrfo024St3XbP5_ohvBSe"
  },
  {
    image: GestionMutuales,
    title: "Gestión de DDJJ",
    description: 'El programa se encarga de cargar préstamos y reclamos en formato de archivo de texto (.txt) y verifica si cumplen con el formato establecido. Utiliza una base de datos en PostgreSQL compartida para almacenar la información procesada.',
    githubLink: "https://github.com/cozakoo/Java_SistemaGestionDeclaracionesMutuales",
    liveLink: "https://drive.google.com/drive/folders/1H8b-X6F6XfUNnhw-2SWo1jOQUa6AbT5j"
  },
  {
    image: ControlTransacciones,
    title: "Control de Transacciones",
    description: 'Compara una salida de la AS400 donde se tiene el detalle de las transacciones e informa en el caso de no cumplir con un formato predeterminado.',
    githubLink: "https://github.com/cozakoo/Java_ControlTransacciones",
    liveLink: "https://drive.google.com/drive/folders/1Hihl1T5JPvqPuPc_hA3KRKdIQogXLvHW"
  },
  {
    image: Embalsado,
    title: "Embaldosado de Sierpinski",
    description: "El embaldosado de Sierpinski es un patrón fractal basado en el triángulo de Sierpinski, hecho con Python y PyGame.",
    githubLink: "https://github.com/cozakoo/Python_EmbaldosadoSierpinski",
    liveLink: "https://github.com/cozakoo/Python_EmbaldosadoSierpinski"
  },
  {
    image: FollowX,
    title: "Unfollow/Follow en X ",
    description: 'Página web que permite seguir o dejar de seguir a un usuario en la plataforma "X", manteniendo la foto de perfil actualizada.',
    githubLink: "https://github.com/cozakoo/React_TwitterFollow?tab=readme-ov-file",
    liveLink: "https://martin-arcos-twitter.netlify.app/"
  },
    {
    image: TicTacToe,
    title: "Tic Tac Toe",
    description: 'Un clásico juego de Tic Tac Toe, conocido como "Ta-Te-Ti" en Argentina y "Tres en Línea" en Chile, hecho con JavaScript.',
    githubLink: "https://github.com/cozakoo/JavaScript_TicTacToe",
    liveLink: "https://martin-arcos-tictactoe.netlify.app/"
  }
];

export default projectData;
