import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import imge from '../../assets/images/main-circle.png'
import { motion } from 'framer-motion'

const Skills = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      // setLetterClass('text-animate-hover')
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <>
      <div className="container2 skills-page">
        <br />
        <br />
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={['S', 'k', 'i', 'l', 'l', 's']}
            idx={15}
          />
        </h1>
        <div className="skills-container">
  <motion.div drag whileHover={{ scale: 1.01 }} className="skill-table skill-table_animate" style={{ zIndex: 5 }}>
  <table className="table-tg" style={{ width: "100%" }}>
  <caption className="table-caption">Hard Skills</caption> {/* Aquí agregamos el título */}

  <thead>
        <tr>
          <th className="tg-header">Lenguajes</th>
          <th className="tg-header">Frameworks</th>
          <th className="tg-header">DataBase</th>
          <th className="tg-header">OS</th>
          <th className="tg-header">Conocimientos</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="tg-sub-text">Java</td>
          <td className="tg-sub-text">Bootstrap</td>
          <td className="tg-sub-text">Firebase</td>
          <td className="tg-sub-text">Android</td>
          <td className="tg-sub-text">Jira</td>
        </tr>
        <tr>
          <td className="tg-sub-text">Kotlin</td>
          <td className="tg-sub-text">Django</td>
          <td className="tg-sub-text">MongoDB</td>
          <td className="tg-sub-text">Linux</td>
          <td className="tg-sub-text">npm</td>
        </tr>
        <tr>
          <td className="tg-sub-text">Python</td>
          <td className="tg-sub-text">Docker</td>
          <td className="tg-sub-text">PostgreSQL</td>
          <td className="tg-sub-text">Windows</td>
          <td className="tg-sub-text">Git & GitHub</td>
        </tr>
        <tr>
          <td className="tg-sub-text">Ruby</td>
          <td className="tg-sub-text">Flask</td>
          <td className="tg-sub-text">SQLite</td>
          <td className="tg-sub-text"></td>
          <td className="tg-sub-text">Pattern Design</td>
        </tr>
        <tr>
          <td className="tg-sub-text"></td>
          <td className="tg-sub-text">React</td>
          <td className="tg-sub-text"></td>
          <td className="tg-sub-text"></td>
          <td className="tg-sub-text">Trello</td>
        </tr>
        <tr>
          <td className="tg-sub-text"></td>
          <td className="tg-sub-text">Rails</td>
        </tr>
        <tr>
          <td className="tg-sub-text"></td>
          <td className="tg-sub-text">Tailwind</td>
        </tr>
      </tbody>
    </table>
  </motion.div>

  <motion.div drag whileHover={{ scale: 1.01 }} className="skill-table_animate" style={{ zIndex: 1 }}>
    <div className="circle-main">
      <div className="hover-show">
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>

        <span className="circle"></span>
    <span className="circle"></span>
    <span className="circle"></span>
    <span className="circle"></span>
    <span className="circle"></span>
  </div>
</div> 
</motion.div>
</div>

<br />
<br />
<br />
<br />

<div className="skills-container">

  <motion.div drag whileHover={{ scale: 1.01 }} className="skill-table_animate" style={{ zIndex: 1 }}>
    <div className="circle-main">
      <div className="hover-show">
        <span className="circle-soft-skill"></span>
        <span className="circle-soft-skill"></span>
        <span className="circle-soft-skill"></span>
        <span className="circle-soft-skill"></span>
        <span className="circle-soft-skill"></span>
        <span className="circle-soft-skill"></span>
        <span className="circle-soft-skill"></span>
        <span className="circle-soft-skill"></span>
      </div>
    </div> 
  </motion.div>

  <motion.div drag whileHover={{ scale: 1.01 }} className="skill-table skill-table_animate" style={{ zIndex: 1 }}>
  <table className="table-tg" style={{ width: "100%" }}>
  <caption className="table-caption">Soft Skills</caption> {/* Aquí agregamos el título */}

      <tbody>
        <tr>
          <td className="tg-sub-text">Comunicación efectiva</td>
          <td className="tg-sub-text">Trabajo en equipo</td>
        </tr>
        <tr>
          <td className="tg-sub-text">Pensamiento crítico</td>
          <td className="tg-sub-text">Resolución de problemas</td>
        </tr>
        <tr>
          <td className="tg-sub-text">Adaptabilidad</td>
          <td className="tg-sub-text">Gestión del tiempo</td>
        </tr>
        <tr>
          <td className="tg-sub-text">Empatía</td>
          <td className="tg-sub-text">Liderazgo</td>
        </tr>
        <tr>
          <td className="tg-sub-text">Resiliencia</td>
          <td className="tg-sub-text">Ética laboral</td>
        </tr>
      </tbody>
    </table>
  </motion.div>
  <br />

</div>

<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
      </div>
    </>
  )
}

export default Skills
