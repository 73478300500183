import './index.scss'
import { useState, useEffect } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'

import contactMe from '../../assets/images/about-me_.jpeg'

import { FaGithub, FaLinkedinIn} from "react-icons/fa";

const Contact = () => {

  const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        
        let timeoutId = setTimeout(() => {
            // setLetterClass('text-animate-hover')
        }, 3000)
        
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

  return (
    <>
    <br />
    <br />
      <div className="container2 contact-page">
        <h1>
          <AnimatedLetters 
            letterClass={letterClass}
            strArray={['C', 'o', 'n', 't', 'á', 'c', 't', 'a', 'm', 'e']}
            idx={15}
            />
        </h1>
        
        <br />
        <br />

        <div drag className="whole-contact">
          <div drag className="background">

          <img src={contactMe} alt="" />
          <h6>Encuentra a <br /> <span>Martín Arcos Vargas</span> <br /> en:</h6>
          
          <div className="contact-icons">
            <button>
              <a className='ic1' href="https://github.com/cozakoo" target='_blank' rel='noreferrer'>
                <FaGithub className="contact-icon" />
              </a>
            </button>
            
            <button>
              <a className='ic2' href="https://www.linkedin.com/in/martin-arcos" target='_blank' rel='noreferrer'>
                <FaLinkedinIn className="contact-icon" />
              </a>
            </button>
          </div>
        </div>
        </div>
        
      </div>

      {/* <Loader type="triangle-skew-spin" /> */}
    </>
  )
}

export default Contact