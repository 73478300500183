import './index.scss';
import { useState, useEffect } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import { FaGlobe, FaGithubSquare } from 'react-icons/fa';
import projectData from './projectData'; // Importar los datos de los proyectos desde un archivo separado

const Projects = () => {
  const [letterClass, setLetterClass] = useState('text-animate');

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      // setLetterClass('text-animate-hover')
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <br />
      <div className="works-container projects-page">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={['T', 'r', 'a', 'b', 'a', 'j', 'o', 's', ' ', 'R', 'e', 'a', 'l', 'i', 'z', 'a', 'd', 'o', 's']}
            idx={15}
          />
        </h1>

        <br />

        <div className="columns">
          <div className="column">
            {projectData.map((project, index) => (
              <div className="item" key={index}>
                <img src={project.image} alt={project.title} loading="lazy" /> {/* Aplicar lazy loading */}
                <div className="item-content">
                  <h6 className="item-content-title">{project.title}</h6>
                  <div className="sub-item-content">
                    <p>{project.description}</p>
                    <div className="item-icon">
                      <a href={project.githubLink} target="_blank" rel="noreferrer">
                        <FaGithubSquare className="ic" />
                      </a>
                      <a href={project.liveLink} target="_blank" rel="noreferrer">
                        <FaGlobe className="ic" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
