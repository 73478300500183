import React, { useEffect, useState } from 'react';
import './index.scss'; // Asegúrate de importar tus estilos
import { Link } from 'react-router-dom';
import AnimatedLetters from '../AnimatedLetters'; // Supongo que esto es otro componente que tienes definido
import homeGIF from '../../assets/images/home-simpson.gif'; // Importa tu imagen si la necesitas

const Home = () => {
    const [index, setIndex] = useState(0);
    const phrases = [
        "Analista en Sistema",
        '"No tengas miedo de fallar, ten miedo de no intentarlo"',
        "Desarrollador Web",
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % phrases.length);
            
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="container2 home-page">
            <br />
            <br />
            <h1>
                <span className="text-animate _12">H</span>
                <span className="text-animate _13">o</span>
                <span className="text-animate _14">l</span>
                <span className="text-animate _15">a,</span>
            </h1>

            <h1>
                <span className="text-animate _16">S</span>
                <span className="text-animate _17">o</span>
                <span className="text-animate _18">y</span>&nbsp;
                <AnimatedLetters letterClass="text-animate" strArray={['M', 'a', 'r', 't', 'í', 'n', ' ', 'A', 'r', 'c', 'o', 's']} idx={15} />
            </h1>

            <br />

            {/* Renderizamos la frase actual */}
            <h2>{phrases[index]}</h2>

            <br />


            <div className="button-row">
                <Link to="/contact" className="button">Contáctame</Link>
                <Link to="/download-cv" className="button">Descargar CV</Link>
            </div>


            <div className="text-center">
                <img className='gif' src={homeGIF} alt="gif" />
            </div>
        </div>
    );
}

export default Home;
